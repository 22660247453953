@import "../../../../shared/style/variables";
@import "../../../../shared/style/typography";

.details {
  @include font-regular;
  display: flex;
  flex-direction: column;
  padding: 130px 0 0 18px;
  .category {
    font-size: 16px;
    letter-spacing: 0.19px;
    margin: 0;
  }
  .title {
    font-size: 18px;
    letter-spacing: 0.23px;
    line-height: 24px;
    margin-bottom: 12px;
  }
  .narrator,
  .artist {
    font-size: 13px;
    letter-spacing: 0.16px;
    margin-top: 0;
  }
  .favorite {
    margin: 1rem auto;
    border-radius: 50%;
    background-color: $color-primary-blue;
    cursor: pointer;
    &.active {
      background-color: $color-primary-white;
    }

    .favorite-heart {
      padding: 5px;
      display: block;
    }
  }
  .description {
    font-size: 16px;
    letter-spacing: 0.19px;
  }
}
