@import "../../../../shared/style/variables";
@import "../../../../shared/style/typography";

.player-menu {
  padding-bottom: 8rem;
  .component-box.with-header {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    height: 100%;
  }
  .table {
    th {
      border-right: unset;
    }
    line-break: unset;
    .header-label {
      background-color: rgba($color-primary-light, 0.6);
    }

    .song-title {
      display: flex;
      flex-direction: row;
      p {
        @include lg {
          font-size: unset;
        }
        font-size: 12px;
        margin: auto 24px auto 0;
      }
      .thumbnail-container {
        position: relative;
        .thumbnail {
          margin: 12px 12px 12px 0;
          border-radius: 11px;
        }
        .favorite {
          position: absolute;
          bottom: 19px;
          left: 4px;
          width: 16px;
          padding: 2px;
          background-color: rgba($color-primary-white, 0.8);
          border-radius: 50%;
        }
      }
    }
  }

  th:nth-of-type(2) {
    padding-left: 25px;
    @include lg {
      width: 284px;
    }
    width: 220px;
    border-radius: 24.5px 0 0 24.5px;
  }

  th:nth-of-type(6) {
    border-radius: 0 24.5px 24.5px 0;
  }
}
