@import "../../style/variables";
@import "../../style/typography";

.user-page {
  padding-bottom: 3rem;
  .component-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 1.5em;
    h1,
    h2 {
      margin-top: 2em;
    }
    h1,
    h2,
    h3 {
      @include font-heading;
    }
    h3 {
      font-size: 22px;
    }
    background-color: $color-form-light;
  }

  .subscription-content {
    display: flex;
    flex-wrap: wrap;
    .data-group {
      margin-right: 20px !important;
    }
    .data-group:nth-child(2),
    .data-group:nth-child(4) {
      margin-right: 0 !important;
    }
    .field-name {
      @include font-regular;
      color: $color-primary-dark;
      text-align: left;
      margin-bottom: 0.4rem;
      margin-left: 15px;
    }

    .value {
      @include font-bold;
      text-align: left;
      line-height: 270%;
      color: $color-text-dark;
      height: 46px;
      width: 300px;
      max-width: calc(100% - 0.4rem - 1.1rem);
      padding: 0 0 0 20px;

      border-radius: 1.8em;
      border-style: none;

      background-color: $color-primary-white;
    }
    .date {
      width: 128px;
    }
  }

  .content {
    @include sm {
      padding: 0 2.5em;
    }
    @include md {
      padding: 0 4.8em;
    }

    .data-group {
      margin: auto 0;
      max-width: 100%;

      .device {
        @include font-regular;
        color: $color-text-dark;
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 0.23px;
      }
    }

    .btn {
      width: 200px !important;
      @include sm {
        margin: 0.4em auto;
      }
      @include md {
        margin: 1.2em 0;
      }
    }

    .actions {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @include sm {
        flex-direction: column;
        margin-bottom: 2rem;
      }
      @include md {
        flex-direction: row;
        margin-bottom: 3rem;
        button {
          margin: auto;
        }
      }
    }

    .form-control {
      background-color: $color-primary-white !important;
    }

    .checkbox {
      @include font-bold;
      padding: 0.4em 0.8em 0.4em 20px;
      margin-top: 2rem;
      font-size: 18px;
      line-height: 180%;
      &.edit {
        margin-top: 0;
        cursor: pointer;
      }
    }
    .checkbox:last-of-type {
    }

    .drop-down {
      margin: 15px 0;
    }
    .fine-print {
      padding-left: 1rem;
      text-align: left;
      color: $color-primary-dark;
    }

    .hidden {
      display: none;
    }
    .row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      @include sm {
        gap: 0.8rem;
        flex-direction: column;
      }
      @include lg {
        gap: 1.8rem;
        flex-direction: row;
      }

      .device-group {
        display: flex;
        flex: 1;

        justify-content: space-between;
        background-color: $color-primary-white;
        color: $color-text-dark;

        border-radius: 25px;
        padding: 0 20px 0 20px;
        .device {
          @include font-bold;
        }
        p {
          margin: 13.5px 0;
        }

        &.empty {
          background-color: rgba($color-primary-white, 0.6);
          text-align: center;
          p {
            width: 100% !important;
          }
        }
      }
      img.active {
        cursor: pointer;
      }
      .btn {
        width: 100% !important;
      }
    }
  }

  label {
    &.checkbox {
      width: 85%;
      height: 3rem;
      margin: 19px 0 12px;
      border-radius: 1.8em;
      border-style: none;

      background-color: $color-primary-white;
    }
  }
  input[type="checkbox"] + span {
    width: 46px;
    height: 46px;
    border-radius: 25px;
    border: 8px solid $color-primary-white;
  }

  input[type="checkbox"]:not(checked) + span,
  input[type="checkbox"]:checked + span {
    left: 110%;
    top: 0;
  }

  [type="checkbox"]:checked + span:after {
    color: $color-primary-dark;
  }

  [type="checkbox"]:not(:checked) + span:after {
    opacity: 0;
  }

  [type="checkbox"]:checked + span:after {
    opacity: 1;
  }

  [type="checkbox"][disabled=""]:not(:checked) + span {
    background-color: rgba($color-primary-white, 0.6);
    border: none;
  }
}

.responsive-table.subscription-history {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;

  justify-content: space-evenly;
  .wrapper {
    overflow: scroll;
    flex: 1;
    table {
      box-sizing: border-box;
      flex: 1;
      width: 100%;
      align-content: center;
      th {
        text-align: left;
        white-space: nowrap;
      }
      tr:hover {
        background-color: #3e2694;
      }
      td {
        white-space: nowrap;
        padding: 0.3em 0.3em;
      }
      tbody {
        overflow: scroll;
      }
    }
  }
}

.Modal-small {
  h2 {
    text-align: left;
  }
  .label {
    text-align: left;
    font-size: 1.6rem;
    line-height: 1.2em;
  }
  .value {
    input,
    select {
      width: 100%;
      max-width: 30rem;
      outline: 0;
      border-width: 0 0 2px;
      border-color: #c0c1ff;
      background-color: transparent;
      color: #c0c1ff;
      @include font-regular;
      font-size: 20px;
      line-height: 1.2em;
    }
  }
}
