@import "../../../shared/style/variables";

.download-widget {
  display: flex;
  justify-content: center;
  @include sm {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: auto auto 2rem;
  }
  @include md {
    flex-direction: row;
  }
  img {
    max-height: 50px;
    max-width: 150px;
    @include md {
      max-height: 42px;
    }
  }
}
