@import "variables";
@import "typography";
@import "buttons";

#CybotCookiebotDialogPoweredbyLink {
  display: none !important;
}
#CybotCookiebotDialog,
#CybotCookiebotDialogBody,
#CybotCookiebotDialogBodyContent,
#CybotCookiebotDialogBodyContentTitle,
#CybotCookiebotDialogBodyContentText,
#CybotCookiebotDialogBodyButtonAccept {
  font-family: Lato, sans-serif !important;
  color: $color-primary-main;
  a {
    color: $color-primary-main;
  }
  h2 {
    color: $color-primary-main;
  }
}

#CybotCookiebotDialogBodyButtonAccept {
  @include gold-button;
  font-family: Lato, sans-serif !important;
}
