@import "../../../../../shared/style/variables";

.try-moshi-image-wrapper {
  .try-moshi-sun-lines {
    position: absolute;
    display: none;
    @include md {
      display: block;
    }
    &.top {
      right: 0;
      top: 0;
      transform: translate(25%, -25%);
    }
    &.bottom {
      left: 0;
      bottom: 0;
    }
  }
  .try-moshi-phone-image {
    height: 80%;
    z-index: 2;
    @include md {
      padding-left: 12%;
    }
    @include lg {
      height: 100%;
      padding-left: 0;
    }
  }
  .try-moshi-phone-shadows {
    display: none;
    @include lg {
      display: block;
    }

    position: absolute;
    top: 54px;
    left: 54px;
  }
}
