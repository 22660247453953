@import "../../../../../shared/style/variables";

.content-filter {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  //gap: 28px;
  margin: -14px;
  padding: 25px 78px;
}

.content-filter > * {
  margin: 14px;
}
