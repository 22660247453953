$screen-sm-min: 280px;
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

$screen-md-min: 661px;
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

$screen-lg-min: 1126px;
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

$screen-xl-min: 1400px;
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

$screen-short-max: 800px;
@mixin short {
  @media (max-height: #{$screen-short-max}) {
    @content;
  }
}

/*
  Sizes
 */
$width-logo-mobile: 80px;
$width-logo-desktop: 133px;

$height-navigation-mobile: 80px;
$height-navigation-desktop: 120px;

$height-icon-button: 34px;

/*
  Colors
 */
$color-primary-dark: #05073c;
$color-primary-main: #3b338a;
$color-primary-light: #72d7f2;
$color-primary-white: #ffffff;
$color-primary-off-white: #ecece7;
$color-primary-blue: #0f8eb3;

$color-primary-teal: #00c8b1;
$color-form-light: #c8f4ff;
$color-form-input: #ecfbff;

$color-text-dark: #010b3b;
$color-text-blue: #72d7f2;
$color-text-orange: #ff8600;
$color-text-purple: #8d6dea;
$color-text-green: #00c8b1;
$color-text-grey: #2c2c2d;
$color-text-grey-disabled: #999999;
$color-text-error: #ff5454;

$color-brand-royal: #321a72;
$color-brand-coral: #f46363;

$color-button-trial: #f8cf03;
$color-button-play: #fb7f04;
$color-button-link: #010b3b;
$color-button-coral: #f46363;
$color-button-disabled: #cccccc;
$color-button-selected: #00c8b1;
$color-button-secondary: #72d7f2;

$color-icon-active: #72d7f2;
$color-icon-grey: #2c2c2d;
$color-icon-default: #ffffff;

$color-tag-free: #c7a2fb;
$color-tag-new: #f46363;

$color-system-alert: #ff5454;
$color-system-warning: #ff8600;
$color-system-success: #11b753;

$color-box-shadow: #0000004f;

$color-gradient-dark: #5459aa;
$color-gradient-mid: #3060b2;
$color-gradient-light: #089de3;

$color-gradient-text-light: #3985cb;
/*
* Gradients
*/

$gradient-component-box: linear-gradient(
  to bottom left,
  $color-gradient-dark 0%,
  $color-gradient-mid 51%,
  $color-gradient-light 100%
);

$gradient-breathing-text: linear-gradient(
  to right,
  $color-primary-white 25%,
  $color-gradient-text-light 100%
);
