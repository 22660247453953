@import "../../../shared/style/variables";
@import "../../../shared/style/typography";

.hero-module {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  overflow: hidden;
  background-color: $color-primary-light;
  @include sm {
    justify-content: space-between;
    height: 100vh;
  }
  @include md {
    padding: 0 5rem;
  }
  @include lg {
    height: auto;
    flex-direction: row;
  }
  @media (min-width: 2009px) {
    justify-content: center;
  }
  .hero-module-text,
  .hero-module-image {
    padding-top: 120px;
    @include sm {
      padding-top: 15vh;
      text-align: center;
    }
    @include md {
      padding-top: 240px;
    }
    @include lg {
      position: relative;
      text-align: left;
    }
    z-index: 1;
  }
  .hero-module-text {
    display: flex;
    flex: 0 0 36%;
    @include lg {
      /** Safari flex shrink workaround */
      min-width: 490px;
    }

    flex-direction: column;
    white-space: pre-wrap;
    h1 {
      @include font-heading;
      color: $color-primary-white;
      font-size: 32px;
      line-height: 110%;
      @include lg {
        margin: 0 0 1.6rem 0;
        padding-right: 10rem;
        font-weight: 900;
        font-size: 52px;
      }
      @include xl {
        font-size: 66px;
        top: 258px;
      }
    }
    h4 {
      @include font-sub-heading;
      color: $color-primary-white;
      line-height: 130%;
      @include sm {
        @include font-text;
        font-size: 18px;
        margin: 1rem 1.8rem;
      }
      @include md {
        display: block;
        padding: 0.6rem 0 1.4rem;
        margin: 0 4rem 1.3rem;
        font-size: 26px;
      }
      @include lg {
        margin: 0 0 1.3rem;
      }
    }
    a {
      margin: 0 auto 4rem 0;
      @include sm {
        margin: 2rem auto;
      }
      @include lg {
        margin: 0 auto 4rem 0;
      }
      @include xl {
        margin-bottom: 6rem;
      }
    }
    .hero-module-text-partner-logo {
      object-fit: contain;
      max-width: 215px;
      max-height: 162px;
      @include sm {
        margin: auto;
      }
      @include lg {
        margin: unset;
      }
    }
  }
  .hero-module-image {
    @include lg {
      display: flex;
    }
    flex-shrink: 1;
    @include sm {
      padding-top: 0;
    }
    @include md {
      padding-top: 0;
      width: auto;
    }
    img {
      align-self: end;
      @include sm {
        width: 100%;
      }
      @include md {
        width: auto;
      }
    }
  }

  .hero-module-cta {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 8%;
    width: 100%;
    z-index: 3;
  }
  .hero-module-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: linear-gradient(
      135deg,
      rgba(2, 0, 36, 0) 50%,
      rgba(255, 255, 255, 0.5) 100%
    );

    z-index: 0;
  }
  &.partners {
    background-color: $color-primary-teal;
    .hero-module-text {
      a {
        margin-bottom: 4rem;
      }
    }
    .hero-module-cta {
      bottom: 1%;
    }
  }
  &.sleepies {
    height: auto;
    padding-bottom: 14vh;
    @include md {
      height: inherit;
      justify-content: space-evenly;
    }
    .hero-module-image {
      padding-top: 120px;
      @include sm {
        padding-top: 5vh;
      }
      @include md {
        padding-top: 140px;
      }
    }
    .hero-module-cta {
      bottom: 4%;
    }
  }
}
