@import "../../../../shared/style/variables";

$rhap_theme-color: $color-primary-white !default;
$rhap_background-color: rgba($color-primary-blue, 0.8) !default;
$rhap_bar-color: rgba($color-primary-white, 0.3) !default;
$rhap_time-color: $color-primary-white !default;

@import "react-h5-audio-player/src/styles.scss";

.player {
  width: 500px;
  height: 500px;
  position: relative;
  .background {
    height: 500px;
    width: 500px;
    object-fit: cover;
    border-radius: 11px;
  }
  .lottie {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    position: relative;
    width: 500px;
    height: 500px;
    border-radius: 11px;
    overflow: hidden;
    &.hidden {
      display: none;
    }
    svg {
      width: 102% !important;
      height: 102% !important;
    }
  }
  .rhap_container {
    position: absolute;
    width: 100%;
    bottom: 0;
    border-radius: 0 0 11px 11px;
    padding: 13px 24px;
    .rhap_time {
      font-size: 13px;
    }
    .rhap_volume-indicator {
      background-color: $color-primary-white;
    }
    .rhap_progress-indicator {
      height: 16px;
      width: 16px;
      margin-top: 2px;
    }
    .rhap_volume-indicator {
      height: 10px;
      width: 10px;
    }
    .rhap_progress-bar {
      height: 4px;
    }
    .rhap_progress-filled {
      background-color: $color-primary-light;
    }
    .rhap_volume-bar {
      height: 2px;
    }
    .rhap_main-controls {
      height: 60px;
      width: 174px;
      justify-content: space-between;

      .rhap_play-pause-button {
        height: 51px;
        width: 51px;
      }
      .rhap_forward-button,
      .rhap_rewind-button {
        height: 46px;
      }
    }
  }
}
