@import "../../../shared/style/variables";
@import "../../../shared/style/typography";

.table {
  padding: 3px 0 32px;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  line-break: anywhere;
  .table-header {
    display: table-row;
    height: 42px;
    :not(.spacer) {
      cursor: pointer;
    }
    th {
      padding-left: 5px;
      @include md {
        border-width: 25px;
      }
      border-right: 5px solid $color-primary-light;
    }
    th:first-of-type,
    th:last-of-type {
      @include lg {
        width: 38px;
      }
      .fa-sort,
      .fa-sort-up,
      .fa-sort-down {
        display: none;
      }
      width: 8px;
      background-color: transparent;
      pointer-events: none;
      border: none;
    }
  }
  .header-label {
    @include font-regular;
    text-align: center;
    @media (min-width: 850px) {
      font-size: 13px;
    }
    font-size: 8px;
    padding: 8px 0;
    background-color: $color-primary-light;
  }
  .spacer {
    @include md {
      width: 38px;
    }
    width: 8px;
    height: 12px;
  }
  tbody {
    td {
      padding-left: 5px;
    }
    td:first-of-type,
    td:last-of-type {
      border: none;
    }
    td:nth-of-type(2) {
      padding-left: 25px;
    }
    tr {
      cursor: pointer;
      td {
        @include lg {
          font-size: 16px;
        }
        font-size: 10px;
        border-bottom: 1px solid rgba($color-primary-white, 0.3);
      }
    }
    tr:hover {
      background-color: rgba($color-primary-light, 0.6);
    }
  }
}

.fa-sort,
.fa-sort-up,
.fa-sort-down {
  padding-left: 12px;
}
