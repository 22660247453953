@import "../../../../../shared/style/variables";

.reviews {
  display: flex;
  flex-direction: column;
  margin: 0 0 6rem;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  .swiper-container {
    width: 134%;
    margin: 0 -17%;
    overflow-x: visible;

    @include md {
      width: 100%;
      margin: 0 auto;
    }
  }

  .reviews-swiper {
    position: relative;
    padding: 4rem 0;
  }
  .btn {
    margin: 0 auto;
  }
}

.swiper-slide {
  width: 80%;
}
.swiper-slide-next,
.swiper-slide-prev {
  transform-origin: center;
  transition: transform 0.5s;
  @include sm {
    transform: scale(0.9);
  }
  @include md {
    transform: unset;
  }
}
.swiper-slide-next {
  @include md {
    transform: translateY(30px) rotateZ(-5deg);
  }
}
.swiper-slide-prev {
  @include md {
    transform: translateY(30px) rotateZ(5deg);
  }
}
.swiper-slide-active {
  transform-origin: center;
  transition: transform 0.5s;
  z-index: 99;
}

.swiper-button-next,
.swiper-button-prev {
  width: 44px;
  height: 44px;
}
.swiper-button-next {
  right: 70px;
  left: auto;
}
.swiper-button-prev {
  left: 70px;
  right: auto;
}
