@import "../../../shared/style/variables";
@import "../../../shared/style/typography";

.landing-page {
  &.app-body {
    padding: unset;
    background-color: $color-primary-white;
  }
  padding-bottom: 0 !important;
  padding-top: 0;
  .component-box.with-header {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 2em 2em 10.1em 2em;
    a {
      width: 286px;
      margin: 1em auto 3em auto;
    }
    .component-box-header {
      padding-top: 0.9em;
      padding-bottom: 0.6em;
    }
  }
  .bottom-padding {
    width: 100%;
    height: 140px;
    background-color: $color-primary-light;
    @include md {
      display: none;
    }
  }
}
