@import "../../../../shared/style/variables";
@import "../../../../shared/style/typography";

.select-button-wrapper {
  position: relative;
  .select-button {
    display: flex;
    flex-direction: column;
    background-color: rgba($color-primary-white, 0.6);
    border: 2px solid transparent;
    border-radius: 35px;

    &.selected {
      background: $color-primary-white;
      border: 2px solid $color-primary-dark;
    }

    .select-button-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @include sm {
        padding: 0.55rem 1rem;
      }
      @include md {
        padding: 0.8rem 1rem;
      }
      .select-button-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
        padding-left: 18px;

        .title {
          margin: 0;
          @include font-bold;
          font-size: 16px;
        }

        .body {
          @include font-regular;
          font-size: 13px;
        }
      }
      .select-button-selected-icon {
        align-self: center;
        margin-right: 10px;

        display: none;
        &.selected {
          display: block;
        }
      }
    }
  }
  .badge {
    position: absolute;
    top: -14px;

    background-color: $color-primary-dark;
    border-radius: 12px;
    height: 24px;
    width: 6rem;
    margin-left: 35px;
    p {
      @include font-bold;
      color: $color-primary-white;
      text-align: center;
      font-size: 13px;
      height: 100%;
      line-height: 22px;
      margin: 0;
    }
  }
}
