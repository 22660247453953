@import "../../../../../shared/style/variables";
@import "../../../../../shared/style/typography";

.questions-answered {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 2rem;
  max-width: 90%;

  @include sm {
    padding: 0 1.3em;
  }
  @include lg {
    width: 760px;
  }
  h2 {
    @include font-heading;
    line-height: 120%;
    text-align: center;
  }
  img {
    @include sm {
      max-width: 90%;
    }
    @include md {
      max-width: 402px;
    }
    align-self: center;
    padding-bottom: 4rem;
  }

  .sliding-tab {
    background-color: $color-primary-off-white;
    border-radius: 35px;
    padding: 0 2rem;
    margin-bottom: 2rem;
    h4 {
      @include font-heading;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      margin: 16px 0;

      @include md {
        font-size: 20px;
        margin: 18px 0;
      }
      cursor: pointer;
      position: relative;
      span {
        margin-right: 5rem;
      }
    }
    ul {
      display: none;
      padding: 1rem 0;
      @include md {
        padding: 2rem 0 3rem;
      }
      background-color: $color-primary-off-white;
      color: $color-text-dark;
    }
    li {
      width: 760px;
      @include sm {
        max-width: 90%;
      }

      padding-bottom: 0.5em;
    }
    .plus {
      align-self: center;
      width: 20px;
      transition: transform 0.2s ease;
    }
    .inner {
      transition: max-height 0.5s ease;
    }
    &.open {
      border-radius: 28px;
      span {
        transform: rotateZ(0deg);

        background-color: transparent;
      }
      ul {
        display: block;
        background-color: $color-primary-off-white;
      }
      .plus {
        transform: rotateZ(45deg);
        @include md {
          width: 20px;
        }
      }
    }
  }
}
