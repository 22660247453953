@import "../../../shared/style/typography";

.player-page {
  padding-bottom: 4rem;
  .component-box.with-header {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 900px;
    padding: 0 63px;
    .component-box-header {
      margin-left: -46px;
      padding-bottom: 1rem;
      height: 24px;
      display: flex;
      align-items: flex-start;
      .back {
        cursor: pointer;
      }
    }
    .container {
      display: flex;
      flex-direction: row;
      padding-bottom: 46px;
    }
  }
}
