@import "../../../shared/style/variables";
@import "../../../shared/style/typography";

.footer {
  background-color: $color-primary-off-white;
  padding-top: 3rem;
  padding-left: 2rem;
  @include md {
    padding-left: 12rem;
  }
  .footer-content-wrapper {
    max-width: 65%;
    .footer-website-links {
      display: flex;
      flex-direction: column;
      @include md {
        flex-direction: row;
      }
      justify-content: space-between;
      margin-bottom: 3.4rem;
      .footer-link-group {
        display: flex;

        flex-direction: column;
        .header {
          @include font-heading;
          margin: 0 0 20px;
          font-size: 20px;
          color: $color-text-blue;
        }
        .link {
          @include font-bold;
          text-decoration: none;
          margin: 0 0 20px;
          font-size: 20px;
          text-align: left;
          color: $color-primary-dark;
        }
      }
    }
    .footer-app-links {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: 20px;
      @include md {
        flex-direction: row;
        margin-bottom: 60px;
      }

      .header-logo {
        margin-left: 10px;
        margin-bottom: 15px;
        @include md {
          margin-left: 0;
          margin-bottom: 0;
        }
        padding-right: 60px;
      }

      .download-button-wrapper {
        display: flex;
        flex-direction: column;
        @include md {
          flex-direction: row;
        }
      }

      .padding-right {
        padding-right: 30px;
      }

      .footer-links-legal {
        display: flex;
        padding-left: 0;
        flex-direction: column;
        @include md {
          flex-direction: row;
          padding-left: 30px;
        }
        margin-top: 0.6rem;
        @include md {
          li:first-of-type {
            display: inherit;
            padding-left: 0;
          }
        }
        li {
          display: list-item;
          color: $color-primary-dark;
          padding: 0.4rem 1rem;
        }
        a {
          padding-left: 0;
          color: $color-primary-dark;
        }
      }
    }

    .footer-terms {
      padding-bottom: 100px;
      @include md {
        padding-bottom: 60px;
      }
      p {
        color: $color-primary-dark;
        margin: 0;
      }
    }
  }
}
