@import "../../../../shared/style/variables";
@import "../../../../shared/style/typography";

.signup-page {
  position: relative;
  .component-box.with-header {
    position: static;
    @include sm {
      background-color: transparent;
    }
    @include md {
      background-color: $color-form-light;
    }
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 4;
  }

  .re-captcha {
    padding: 1.6rem 0 1rem;
    text-align: center;
    transform: scale(0.96);
    display: flex;
    flex-direction: column;
    justify-content: center;
    .error-message {
      position: relative;
      margin: auto;
    }
  }
  .drop-down {
    max-width: 100%;
    margin: 18px 0;
  }
  .component-after {
    .terms {
      @include font-heading;
      font-size: 14px;
      text-align: left;
    }
    .text-below {
      @include font-regular;
      font-size: 12px;
      text-align: left;
      line-height: 14px;
      margin-top: -0.5em;
    }
  }
}
