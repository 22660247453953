@import "../../../shared/style/variables";

.awards-wrapper {
  display: none;
  margin: auto;
  @include md {
    display: flex;
    justify-content: flex-start;
  }
  @include lg {
    margin: inherit;
  }
  flex-direction: column;
  a {
    margin-top: 0;
  }
  .awards {
    display: flex;
    padding-bottom: 4rem;
    height: 45px;
  }
  .award {
    margin-right: 1.6rem;
    width: auto;
    @include md {
      max-height: 45px;
    }
  }
}
