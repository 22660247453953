@import "../../../../shared/style/variables";
@import "../../../../shared/style/typography";

.login-page {
  position: relative;
  min-height: 80vh;

  .component-box.with-header {
    position: static;
    background-color: $color-form-light;
    display: flex;
    flex-direction: column;
    .component-box-header {
      .subtitle {
        @include font-bold;
        @include sm {
          @include small;

          margin-bottom: 0;
        }
        @include md {
          @include large;
          margin-bottom: 1rem;
        }
      }
    }
    .component-after {
      @include sm {
        margin: 1.2rem 0 1rem;
      }
    }
  }
}

.confirm-email {
  .component-box.with-header {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
