@import "../../../style/variables";
@import "../../../style/typography";
.drop-down {
  position: relative;
  @include md {
    min-width: 349px;
  }
  height: 46px;

  color: $color-primary-dark;
  background-color: rgba($color-primary-white, 0.3);
  border-radius: 29px;
  z-index: 4;
  &.open {
    background-color: transparent;
    .drop-down-title {
      background: white;
      border-radius: 22px 22px 0 0;
    }
  }
  &.with-selection {
    background-color: $color-primary-white;
  }
  .drop-down-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    padding: 0 30px;
    cursor: pointer;
    z-index: 6;
    .drop-down-arrow {
      width: 24px;
      margin: auto 0;
      z-index: 7;
    }
    .title-text {
      @include font-bold;
      font-size: 18px;
      line-height: 12px;
      z-index: 7;
    }
  }
  .options {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-top: -11px;
    background: $color-primary-white;
    border-radius: 0 0 22px 22px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 40vh;
    z-index: 5;
    .option {
      padding: 0 30px;
      cursor: pointer;
    }
    .option:hover,
    .selected {
      color: $color-primary-white;
      background-color: $color-primary-blue;
    }
    .option-text {
      @include font-regular;
      font-size: 18px;
    }
  }
  + .error-icon {
    top: 10px;
    right: 53px;
    z-index: 4;
  }
  .clear-selection {
    @include font-bold;
    color: $color-button-link;
    background-color: $color-primary-white;
    border-radius: 22px 22px 0 0;
    text-decoration: underline;
    text-align: center;
    font-size: 16px;
    cursor: pointer;

    margin-top: 0;
    padding-top: 16px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  ::-webkit-scrollbar {
    height: 11px !important;
    width: 11px;
    background: transparent;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.5);
    border: 2px solid white;
  }
}
